<template>
  <router-view/>
</template>

<script>
export default {
  name: "HomeRoute",
  created() {
    this.$store.dispatch('matchStatus/getAll');
    this.$store.dispatch('supplier/suppliers');
    this.$store.dispatch('users/getall');
  }
}
</script>

<style scoped>

</style>
